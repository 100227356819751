import React, { useContext } from 'react';
import { View, Text, Button } from 'react-native';
import SessionContext from '../context/SessionContext';

const HomeScreen = ({ navigation }) => {
    const { sessionData, userData, signOut } = useContext(SessionContext);

    return (
        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <Text>Hello, {userData?.username || 'Guest'}</Text>
            <View style={{ flexDirection: 'row', marginTop: 20 }}>
               {/* <Button title="Discover" onPress={() => navigation.navigate('Discover')} />
                <Button title="Add" onPress={() => navigation.navigate('Add')} />
                <Button title="Search" onPress={() => navigation.navigate('Search')} />
                <Button title="Profile" onPress={() => navigation.navigate('Profile')} />*/}
                <Button title="Sign Out" onPress={signOut} />
            </View>
        </View>
    );
};

export default HomeScreen;
