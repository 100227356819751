import React from 'react';
import  AppNavigator  from './src/navigation/AppNavigator';
import { SessionProvider } from './src/context/SessionContext';
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import * as Linking from 'expo-linking';


const App = () => {
    const navigationRef = useNavigationContainerRef();
    const linking = {
        prefixes: [Linking.createURL('/')],
        config: {
            screens: {
                AuthGoogleSuccess: 'auth/google/success',
                // other screens...
            },
        },
    };

    console.log("Linking.createURL('/')");
    console.log(Linking.createURL('/'));

    return (
        <SessionProvider navigationRef={navigationRef}>
            <NavigationContainer ref={navigationRef} linking={linking}>
                <AppNavigator />
            </NavigationContainer>
        </SessionProvider>
    );
};

export default App;
