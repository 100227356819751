import React, { createContext, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import _ from 'lodash';

const SessionContext = createContext({
    sessionData: null,
    userData: null,
    signOut: () => {},
    setSessionData: () => {},
    setUserData: () => {}
});

export const SessionProvider = ({ children, navigationRef  }) => {
    const [sessionData, setSessionData] = useState(null);
    const [userData, setUserData] = useState(null);

    // Function to fetch user data
    const saveSessionData = (data) => {
        sessionData(data);
        // Additional logic to persist data, e.g., AsyncStorage
    };

    const saveUserData = (data) => {
        setUserData(data);
        // Additional logic to persist data, e.g., AsyncStorage
    };

    const signOut = async () => {
        console.log("handleSignOut");
        // Clear the session data in context
        setSessionData(null);
        setUserData(null);

        // Clear the session data from AsyncStorage
        await AsyncStorage.removeItem('sessionData');
        await AsyncStorage.removeItem('userData');

        // Navigate to the login screen
        // navigationRef.navigate('Login');
        console.log("handleSignOut - DONE");
    };

    const fetchUserData = async (token) => {
        try {
            const response = await fetch(`${Constants.expoConfig?.extra?.env.EXPO_PUBLIC_API_URL}/user/me`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const body = await response.json();
            if (response.ok) {
                setUserData(body.data);
                navigationRef.navigate('HomeNavigator');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const navigateWhenReady = (routeName, params) => {
        if (navigationRef.isReady()) {
            navigationRef.navigate(routeName, params);
        } else {
            // If not ready, wait and retry
            setTimeout(() => navigateWhenReady(routeName, params), 100); // Retry after a delay
        }
    };

    // Load session data from AsyncStorage
    useEffect(() => {
        const loadData = async () => {
            const storedSessionData = await AsyncStorage.getItem('sessionData');
            const storedUserData = await AsyncStorage.getItem('userData');

            if (storedSessionData && storedSessionData !== 'null') {
                console.log("storedSessionData");
                console.log(storedSessionData);
                const parsedSessionData = JSON.parse(storedSessionData);
                setSessionData(parsedSessionData);
                await fetchUserData(parsedSessionData.jwtToken);
            }

            if (storedUserData && storedUserData !== 'null') {
                setUserData(JSON.parse(storedUserData));
            }
        };

        loadData();
    }, []);

    // Save session data to AsyncStorage
    useEffect(() => {
        AsyncStorage.setItem('sessionData', JSON.stringify(sessionData));
        AsyncStorage.setItem('userData', JSON.stringify(userData));
    }, [sessionData, userData]);

    // Check setupRequired for the user
    useEffect(() => {
        console.log("useEffect - Check setupRequired for the user");
        console.log("userData");
        console.log(userData);

        if (_.get(userData, 'setupRequired', []).length !== 0) {
            if (userData.setupRequired.includes('emailConfirmation')) {
                navigateWhenReady('EmailConfirmation');
            }else if (userData.setupRequired.includes('username')) {
                navigateWhenReady('CompleteRegistration');
            }
        }else if (userData) {
            navigateWhenReady('HomeNavigator');
        } else {
            navigateWhenReady('Login');
        }

    }, [userData]);

    return (
        <SessionContext.Provider value={{ sessionData, setSessionData, userData, setUserData, signOut }}>
            {children}
        </SessionContext.Provider>
    );
};

export default SessionContext;
