import React, { useState, useContext } from 'react';
import {View, TextInput, Button, Text, StyleSheet} from 'react-native';
import SessionContext from '../context/SessionContext';
import { idlCallAPI } from '../utils/idlRequest';

const EmailConfirmationScreen = ({ navigation }) => {
    const [confirmationCode, setConfirmationCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { setUserData } = useContext(SessionContext);
    const { sessionData } = useContext(SessionContext);

    const handleConfirmation = async () => {
        const response = await idlCallAPI('POST', '/auth/email/verifyConfirmationCode', { confirmationCode }, sessionData);
        if (response.success) {
            // On successful confirmation we expect to have a user object in the response
            // Once we set updated user data in context, we can navigate to the home screen (handled in SessionProvider
            setUserData(response.data);
        } else {
            setErrorMessage(response.message || 'An error occurred');
        }
    };

    return (
        <View>
            <TextInput
                style={styles.input}
                placeholder="Confirmation Code"
                value={confirmationCode}
                onChangeText={setConfirmationCode}
                secureTextEntry
            />

            {errorMessage ? <Text>{errorMessage}</Text> : null}
            <Button title="Verify" onPress={handleConfirmation} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        padding: 20
    },
    input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 10,
        padding: 10
    },
    errorText: {
        color: 'red',
        marginBottom: 10,
    }
});

export default EmailConfirmationScreen;
