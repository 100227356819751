import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import LoginScreen from '../screens/LoginScreen';
import SignUpScreen from '../screens/SignUpScreen';
import EmailConfirmationScreen from '../screens/EmailConfirmationScreen';
import CompleteRegistrationScreen from '../screens/CompleteRegistrationScreen';
import HomeNavigator from '../navigation/HomeNavigator';
import HomeScreen from '../screens/HomeScreen';

const Stack = createNativeStackNavigator();

const AppNavigator = () => {
    return (
            <Stack.Navigator>
                <Stack.Screen name="Login" component={LoginScreen} />
                <Stack.Screen name="SignUp" component={SignUpScreen} />
                <Stack.Screen name="EmailConfirmation" component={EmailConfirmationScreen} />
                <Stack.Screen name="CompleteRegistration" component={CompleteRegistrationScreen} />
                <Stack.Screen name="HomeNavigator" component={HomeNavigator} />
               {/* <Stack.Screen name="Home" component={HomeScreen} />*/}
                {/* Add other screens here */}
            </Stack.Navigator>
    );
};

export default AppNavigator;
