import SessionContext from '../context/SessionContext';
import { idlCallAPI } from '../utils/idlRequest';
import React, { useState, useContext  } from 'react';
import { View, TextInput, Button, Text, TouchableOpacity, StyleSheet, Image, ActivityIndicator, Modal  } from 'react-native';
import { Linking } from 'react-native';

const LoginScreen = ({ navigation }) => {
    const [usernameOrEmail, setUsernameOrEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { sessionData } = useContext(SessionContext);
    const { setSessionData, setUserData } = useContext(SessionContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [modalVisible, setModalVisible] = useState(false);

    const handleSignInWithGoogle = () => {
        const url = 'https://d2hldnkotdrers.cloudfront.net/api/auth/google/';
        Linking.openURL(url);
    };

    const [input, setInput] = useState({ usernameOrEmail: '', password: '' });


    const handleLoginGoogle = async () => {
        //Redirect to https://d2hldnkotdrers.cloudfront.net/api/auth/google/
        // Open webview to https://d2hldnkotdrers.cloudfront.net/api/auth/google/

    }
    const handleLogin = async () => {
        console.log("process.env.EXPO_PUBLIC_API_URL");
        console.log(process.env);
        setIsLoading(true);
        setError(null);

        const response = await idlCallAPI('POST', '/auth/email/signin', { usernameOrEmail, password }, sessionData);
        if (response.success) {
            console.log('Login successful');
            setSessionData({
                jwtToken: response.jwtToken,
            });
            setUserData(response.user);
            // navigation.navigate('HomeNavigator');
            // Navigate to HomeScreen or handle email confirmation
        } else {
            console.log('Login failed');
            console.log(response);
            setErrorMessage(response.message || 'An error occurred');
            // Handle error
        }

        setIsLoading(false);

        /*fetch(buildUrl('/auth/email/signin'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: username,
                password: password,
            }),
        })
            .then(async response => {
                if (!response.ok) {
                    // Parse response body as JSON
                    const data = await response.json();
                    console.log(data.message);
                    alert(data.message);
                    throw new Error('Login failed');
                }
                return response.json();
            })
            .then(data => {
                // Handle login success
                // You might want to save the token or navigate to another screen
                alert('Login successful');
            })
            .catch(error => {
                setError(error.message);
            })
            .finally(() => {
                setIsLoading(false);
            });*/
    };

    const handleSignUp = () => {
        // Navigate to Sign Up screen
        navigation.navigate('SignUp');
    };

    const handleForgotPassword = () => {
        // Navigate to Forgot Password screen or logic
    };

    return (
        <View style={styles.container}>
            <Image source={require('../../assets/logo.png')} style={styles.logo} />
            <TextInput
                style={styles.input}
                placeholder="Username or Email"
                placeholderTextColor="#132b4b"
                value={usernameOrEmail}
                onChangeText={setUsernameOrEmail}
            />
            <TextInput
                style={styles.input}
                placeholder="Password"
                placeholderTextColor="#132b4b"
                value={password}
                secureTextEntry
                onChangeText={setPassword}
            />
            <TouchableOpacity style={styles.button} onPress={handleLogin}>
                <Text style={styles.buttonText}>Login</Text>
            </TouchableOpacity>
            {isLoading && <ActivityIndicator />}
            {errorMessage ? <Text style={styles.errorText}>{errorMessage}</Text> : null}
            <Button title="Sign Up" onPress={() => navigation.navigate('SignUp')} />

            <Button title="Sign In with Google2" onPress={handleSignInWithGoogle} />
          {/*  <TouchableOpacity onPress={handleForgotPassword}>
                <Text style={styles.forgotPasswordText}>Forgot Password?</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={handleSignUp}>
                <Text style={styles.signUpText}>Don't have an account? Sign Up</Text>
            </TouchableOpacity>*/}

            <Text style={styles.footerText}>© 2023 Idolist. All rights reserved.</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        padding: 20,
        paddingTop: 0,
        backgroundColor: '#0b0133'
    },
/*    input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 20,
        paddingHorizontal: 10,
    },*/
    forgotPasswordText: {
        marginTop: 10,
        color: 'blue',
        textAlign: 'center',
    },
    signUpText: {
        marginTop: 10,
        color: 'green',
        textAlign: 'center',
    },
    logo: {
        width: 100,
        height: 100,
        alignSelf: 'center',
        marginBottom: 54
    },
    button: {
        borderRadius: 30,
        height: 48,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ca3187',
        shadowColor: 'rgba(82, 51, 86, 0.44)',
        shadowOffset: { width: 0, height: 12 },
        shadowOpacity: 0.44,
        shadowRadius: 13,
        elevation: 20,
        marginBottom: 20,
        marginTop: 16
    },
    buttonText: {
        fontSize: 24,
        color: '#ffffff', // Assuming you want white text
    },
    errorText: {
        fontSize: 18,
        textAlign: 'center',
        color: '#ffa1a1', // Assuming you want white text
    },
    input: {
        height: 44,
        borderRadius: 22,
        fontSize: 18,
        paddingLeft: 24,
        backgroundColor: '#fff',
        color: '#132b4b',
        borderWidth: 0, // equivalent to 'border: none' in CSS
        marginBottom: 8,
        paddingHorizontal: 10,
    },
    footerText: {
        color: '#fff', // Change the color as needed
        fontSize: 14, // Adjust the font size as needed
        textAlign: 'center',
        position: 'absolute', // This positions the text at the bottom
        bottom: 10, // Adjust the distance from the bottom
        width: '100%', // Ensure it spans the width of the screen
    },
});

export default LoginScreen;
