import Constants from 'expo-constants';
import { useContext } from 'react';
import SessionContext from '../context/SessionContext';

const buildUrl = (endpoint) => {
    console.log(Constants.expoConfig);
    return `${Constants.expoConfig?.extra?.env.EXPO_PUBLIC_API_URL}${endpoint}`;
};

const idlCallAPI = async (method, endpoint, data, sessionData) => {
    const headers = { 'Content-Type': 'application/json' };

    if (sessionData?.jwtToken) {
        headers['Authorization'] = `Bearer ${sessionData.jwtToken}`;
    }

    try {
        const response = await fetch(buildUrl(endpoint), {
            method,
            headers,
            body: JSON.stringify(data),
        });

        return await response.json();
    } catch (error) {
        console.error('API call error:', error);
    }
};


export { idlCallAPI };
