import SessionContext from '../context/SessionContext';
import React, { useState, useContext  } from 'react';
import { View, TextInput, Button, Text, StyleSheet } from 'react-native';
import { idlCallAPI } from '../utils/idlRequest';

const SignUpScreen = ({ navigation }) => {
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const { sessionData } = useContext(SessionContext);
    const { setSessionData, setUserData } = useContext(SessionContext);

    const handleSignUp = async () => {
        if (password !== confirmPassword) {
            setErrors({ confirmPassword: "Passwords do not match" });
            return;
        }

        const response = await idlCallAPI('POST', '/auth/email/signup', {
            username,
            name,
            email,
            password
        });

        if (response.success) {
            // Handle successful sign-up, e.g., navigate to home screen or show success message
            setSessionData({
                jwtToken: response.jwtToken,
            });
            setUserData(response.user);
            //navigation.navigate('HomeNavigator');
        } else {
            // Handle errors
            if (response.fieldsMessages) {
                setErrors(response.fieldsMessages);
            } else {
                setErrors({ general: response.message || 'An error occurred' });
            }
        }
    };

    return (
        <View style={styles.container}>
            <TextInput
                style={styles.input}
                placeholder="Username"
                value={username}
                onChangeText={setUsername}
            />
            {errors.username && <Text style={styles.errorText}>{errors.username}</Text>}

            <TextInput
                style={styles.input}
                placeholder="Name"
                value={name}
                onChangeText={setName}
            />
            {errors.name && <Text style={styles.errorText}>{errors.name}</Text>}

            <TextInput
                style={styles.input}
                placeholder="Email"
                value={email}
                onChangeText={setEmail}
                keyboardType="email-address"
            />
            {errors.email && <Text style={styles.errorText}>{errors.email}</Text>}

            <TextInput
                style={styles.input}
                placeholder="Password"
                value={password}
                onChangeText={setPassword}
                secureTextEntry
            />
            {errors.password && <Text style={styles.errorText}>{errors.password}</Text>}

            <TextInput
                style={styles.input}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChangeText={setConfirmPassword}
                secureTextEntry
            />
            {errors.confirmPassword && <Text style={styles.errorText}>{errors.confirmPassword}</Text>}

            <Button title="Sign Up" onPress={handleSignUp} />
            {errors.general && <Text style={styles.errorText}>{errors.general}</Text>}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        padding: 20
    },
    input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 10,
        padding: 10
    },
    errorText: {
        color: 'red',
        marginBottom: 10,
    }
});

export default SignUpScreen;
