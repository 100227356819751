import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import HomeScreen from '../screens/HomeScreen';
// Import other screens: DiscoverScreen, AddScreen, SearchScreen, ProfileScreen

const Tab = createBottomTabNavigator();

const HomeNavigator = () => {
    return (
        <Tab.Navigator>
            <Tab.Screen name="Home" component={HomeScreen} />
           {/* <Tab.Screen name="Discover" component={DiscoverScreen} />
            <Tab.Screen name="Add" component={AddScreen} />
            <Tab.Screen name="Search" component={SearchScreen} />
            <Tab.Screen name="Profile" component={ProfileScreen} />*/}
        </Tab.Navigator>
    );
};

export default HomeNavigator;